import React from "react";
import "./Contact.css";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import { contactInfo } from "../../portfolio";

export default function Contact() {
    return (
        <div className="main contact-margin-top" id="contact">
            <div className="contact-div-main">
                <div className="contact-header">
                    <h1 className="heading contact-title">Reach Out To Me</h1>
                    <p className="contact-text-div">Discuss a project or just want to say hi.</p>

                    <div className="contact-text-div">

                        <a className="contact-detail-email"
                            href={"mailto:" + contactInfo.email_address}>{contactInfo.email_address}</a>
                        <br /><br />

                        <a className="contact-detail"
                            href="tel:+918888087807">+91-8888087807</a>
                        <br /><br />
                        <SocialMedia />
                    </div>
                </div>
                <div className="image-content-profile">
                    <img alt="Contact" src={require("../../assests/images/shreyas.jpg")} className="profile-image" />
                </div>

            </div>
        </div>
    );
}
