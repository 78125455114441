import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Main from "./containers/Main";

import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";

//const trackingId = "UA-63097140-3"; // Replace with your Google Analytics tracking ID
//ReactGA.initialize(trackingId);
const history = createBrowserHistory();

// Initialize google analytics page view tracking
/*
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});
*/

function App() {
  return (
    <Router history={history}>
      <div>
        <Main />
      </div>
    </Router>
  );
}

export default App;
