import React from "react";
import "./StartupProjects.css";


export default function StartupProject() {
  function openProjectInNewWindow(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }

  return (
    <div className="main" id="projects">
      <div>
        <h1 className="skills-heading">Experience</h1>
        <p className="subTitle project-subtitle">SOME STARTUPS AND COMPANIES THAT I HELP TO CREATE THEIR TECH</p>

        <div className="skills">
          <div className="startup-projects-main">
            <div className="skills-image-div ">
              <img alt="Boltpics" onClick={() => openProjectInNewWindow('https://boltpics.com')} src={require("../../assests/images/boltpics.jpg")}></img>
            </div>
            <div className="skills-text-div">
              <h1 onClick={() => openProjectInNewWindow('https://boltpics.com')} className="company-heading">Boltpics</h1>
              <p className="subTitle skills-text-subtitle">Sr. Software Engineer , Co-Founder </p>
              <div>
                <p className="subTitle skills-text">Boltpics is a Smart Photo Distribution platform , which uses our facial recognition algorithms to deliver you your photos .</p>
                <ul>
                  <li>
                    <p className="subTitle skills-text"><b>Technologies</b> : Nodejs , Mongodb , Angular  , Tensorflow , AWS services like EC2 , Elastics Load Balancer , Cloudfront , Lambda etc. and GCP</p>
                  </li>
                  <li>
                    <p className="subTitle skills-text">Responsible for designing and building scalable cloud architecture from concept to production. Involving integration of various third party services like AWS , GCP , Firebase, Payment gateways etc</p>
                  </li>
                  <li>
                    <p className="subTitle skills-text">Exposure to various computer vision technologies like tensorflow and deploying it to production.</p>
                  </li>
                  <li>
                    <p className="subTitle skills-text">Built a highly scalable  architecture with capability of processing 2000 images at a given time. and around 25 lakh photos hosted</p>
                  </li>



                </ul>

                <p className="subTitle skills-text"><a target="_blank" href="https://boltpics.com">visit site</a></p>
              </div>
            </div>
          </div>
        </div>

        <div className="skills">
          <div className="startup-projects-main">
            <div className=" skills-image-div">
              <img alt="Busflix" onClick={() => openProjectInNewWindow('http://busflix.in')} src={require("../../assests/images/busflix2.jpg")}></img>
            </div>
            <div className=" skills-text-div">
              <h1 onClick={() => openProjectInNewWindow('http://busflix.in')} className="company-heading"><a>Busflix</a></h1>
              <p className="subTitle skills-text-subtitle">Software Engineer , Co-Founder</p>
              <div>
                <p className="subTitle skills-text">Busflix is a hardware-software infotainment system for buses with feature like Wifi entertainment  , GPS based automated announcement system , Smart LCD screens etc</p>
                <ul>
                  <li>
                    <p className="subTitle skills-text"><b>Technologies</b> : Nodejs , Sqlite , MySql , AngularJS , Raspberry Pi , Linux Scripts & Drivers , AWS  , Video / Image processing , IP protocols. , Android App development</p>
                  </li>
                  <li>
                    <p className="subTitle skills-text">Responsible for building and setting up whole Technology Infrastructure and automating software processes.</p>
                  </li>
                  <li>
                    <p className="subTitle skills-text">Implemented critical and complex features like Over the Air (OTA) updates , hardware locking , IP protection and network throttling</p>
                  </li>
                </ul>

                <p className="subTitle skills-text"><a target="_blank" href={require("../../assests/images/Busflix.pdf")}>learn more</a></p>

              </div>
            </div>
          </div>
        </div>

        <div className="skills">
          <div className="startup-projects-main">
            <div className="skills-image-div ">
              <img alt="BotsNBrains" onClick={() => openProjectInNewWindow('https://www.botsnbrains.com/')} src={require("../../assests/images/Bots-n-Brains-Logo-png.png")}></img>
            </div>
            <div className="skills-text-div">
              <h1 onClick={() => openProjectInNewWindow('https://boltpics.com')} className="company-heading">Bots N Brains</h1>
              <p className="subTitle skills-text-subtitle">Sr. Software Engineer </p>
              <div>
                <p className="subTitle skills-text">Bots N Brains works as Research & Product Development partners to build select medical device technologies along with all necessary support from Concept to Commercialisation.
</p>
                <ul>
                  <li>
                    <p className="subTitle skills-text"><b>Technologies</b> : Nodejs , MySql , React , AWS services like EC2 , S3 ,Cloudfront </p>
                  </li>
                  <li>
                    <p className="subTitle skills-text">Responsible for designing and building web application from concept to production. Involving integration of various third party services like ERP system, Payment gateways etc</p>
                  </li>

                </ul>

                <p className="subTitle skills-text"><a target="_blank" href="https://www.botsnbrains.com/">visit site</a></p>
              </div>
            </div>
          </div>
        </div>

        <div className="skills">
          <div className="startup-projects-main">
            <div className=" skills-image-div">
              <img alt="Busflix" onClick={() => openProjectInNewWindow('https://canstores.com')} src={require("../../assests/images/canstores.jpg")}></img>
            </div>
            <div className=" skills-text-div">
              <h1 onClick={() => openProjectInNewWindow('https://canstores.com')} className="company-heading"><a>Canstores</a></h1>
              <p className="subTitle skills-text-subtitle">Full Stack Developer</p>
              <div>
                <p className="subTitle skills-text">Modern day hyperlocal retail e-commerce listing portal. Multiple stake-holders like merchants, retailers, buyers, each having its own dashboard and controls.</p>

                <ul>
                  <li>
                    <p className="subTitle skills-text"><b>Technologies</b> :  Nodejs , Mongodb , Angular , AWS , Ionic</p>
                  </li>
                  <li>
                    <p className="subTitle skills-text">Responsible for building and designing cloud based software with role based authorization , accessible  through various endpoints </p>
                  </li>
                  <li>
                    <p className="subTitle skills-text">Built client side application for  IOS , Android and Web platforms</p>
                  </li>
                </ul>
                <p className="subTitle skills-text"><a target="_blank" href="https://canstores.com">visit site</a></p>

              </div>
            </div>
          </div>
        </div>



        <div className="skills">
          <div className="startup-projects-main">
            <div className="skills-image-div">
              <img alt="Simple Solutions" onClick={() => openProjectInNewWindow('http://qssoindia.in/')} src={require("../../assests/images/qsso.png")}></img>
            </div>
            <div className="skills-text-div">
              <h1 onClick={() => openProjectInNewWindow('http://qssoindia.in/')} className="company-heading">Simple Solutions</h1>
              <p className="subTitle skills-text-subtitle">Technology Consultant</p>
              <div>
                <p className="subTitle skills-text">Simple Solutions is a software services company which caters to Web development, Android App development, Cloud infrastructure management, Video production, photography.</p>
                <p className="subTitle skills-text"><a target="_blank" href="http://qssoindia.in/">visit site</a></p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
