import React from "react";
import "./Skills.css";


const skillsSection = {
  skills: [
    "⚡ Develop highly scalable software systems involving complex integration of third party services such as Firebase/ AWS / GCP",
    "⚡ Develop interactive Frontend / User Interfaces for Web and Mobile Applications",
    "⚡ Expertise in building hardware-software networking systems , IOT and embedded systems",
    "⚡ Expertise in various Cloud Technologies",
    "⚡ Experienced startup person who knows what it takes to work under high presssure"
  ],
  softwareSkills: [
    {
      skillName: "NodeJs",
      fontAwesomeClassname: "fab fa-node"
    },
    {
      skillName: "Linux",
      fontAwesomeClassname: "fab fa-linux"
    },
    {
      skillName: "Angular",
      fontAwesomeClassname: "fab fa-angular"
    },
    {
      skillName: "Mongodb",
      fontAwesomeClassname: "fab fa-envira"
    },
    {
      skillName: "Reactjs",
      fontAwesomeClassname: "fab fa-react"
    },

    {
      skillName: "AWS",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },

    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },

    {
      skillName: "Android",
      fontAwesomeClassname: "fab fa-android"
    },


    {
      skillName: "Sql-Database",
      fontAwesomeClassname: "fas fa-database"
    },
    {
      skillName: "Raspberry-Pi",
      fontAwesomeClassname: "fab fa-raspberry-pi"
    },
    {
      skillName: "IOT",
      fontAwesomeClassname: "fas fa-network-wired"
    },

    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    }

  ]
}

export default function Skills() {
  return (
    <div className="main" id="skills">
      <div className="skills-main-div">
        <div className="skills-image-div-main">
          <img alt="Shreyas Working" src={require("../../assests/images/developerActivity.svg")}></img>
        </div>
        <div className="skills-text-div">
          <h1 className="skills-heading">What I do?</h1>
          <p className="subTitle skills-text-subtitle">Co-Founder of 2 small startups , Main role being involved in building technology stack .</p>
          <div>
            {skillsSection.skills.map(skills => {
              return <p key={skills} className="subTitle skills-text">{skills}</p>;
            })}
          </div>
        </div>
      </div>

      <div>
        <h4 className="skills-subhead">Technologies</h4>
        <div className="software-skills-main-div">
          <ul className="dev-icons">
            {skillsSection.softwareSkills.map(skills => {
              return (
                <li key={skills.skillName} className="software-skill-inline" name={skills.skillName}>
                  <i className={skills.fontAwesomeClassname + " skill-icon"}></i>
                  <p className="skill-name" >{skills.skillName}</p>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

    </div>
  );
}
