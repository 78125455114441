import React from "react";
import "./Footer.css";

export default function Footer() {
  return (
    <div className="footer-div">

      {/* <p className="footer-text">Made with ❤️ by Shreyas Ponkshe. Template Design : <a href="https://github.com/saadpasta/developerFolio">here </a> </p> */}
      <p className="footer-text">Made with ❤️ by Shreyas Ponkshe. </p>


    </div >
  );
}
