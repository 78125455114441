import React from "react";
import "./Greeting.css";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import { greeting } from "../../portfolio";

export default function Greeting() {
  return (
    <div className="greet-main" id="greeting">
      <div className="greeting-main">
        <div className="greeting-text-div">
          <div>
            <h1 className="greeting-text">
              {greeting.title}
            </h1>
            <p className="greeting-text-p subTitle">A passionate Software Developer 💻</p>
            <p className="greeting-text-p subTitle">Technology Enthusiast 🚀</p>
            <p className="greeting-text-p subTitle">Entrepreneur 💼</p>

            <SocialMedia />
            <div className="greeting-location">
              <i class="fas fa-map-marker-alt"></i> Pune ,Maharashtra
            </div>
            <div className="button-greeting-div">
              <Button text="Contact me" href="#contact" />
            </div>
          </div>
        </div>
        <div className="greeting-image-div">
          <img alt="shreyas coding" src={require("../../assests/images/coding.svg")}></img>
        </div>
      </div>
    </div>
  );
}
