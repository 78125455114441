import React from 'react';
import './Education.css';

const Education = () => (
  <div className="main edu-margin-top" id="education">
    <div className="edu-div-main">
      <div className="edu-header">
        <h1 className="heading edu-title">Education 🎓</h1>
        <p className="edu-text-div">Graduated with a Bachelor's Degree (B.E.) in Electronics & Telecommunication Engineering from MIT , Pune.
        </p>
        <div className="edu-text-div">
          2012 - 2016
        </div>
      </div>
      <div className="edu-image-div">
        <img alt="Education" src={require("../../assests/images/graduation.svg")}></img>
      </div>
    </div>
  </div>
);


export default Education;
